body {
  padding: 12px;
  margin: 0 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100vw;
}

main {
  max-width: min(calc(100% - 24px), 1024px);
  margin: 0 auto;
}

.logo {
  width: 100%;
  margin-bottom: 44px;
}

@media (min-width: 800px) {
  .logo {
    width: 800px;
    height: 242px;
  }
}

.spin {
  text-align: center;
}
